#headerContainer {
  display: flex;
  gap: 10px;
  right: 0;
  margin-right: 2vw;
  margin-top: 3vh;
  position: absolute;
}

#about,
#projects,
#experience,
#journey {
  font-family: "Courier New", Courier, monospace;
  font-size: 1.1rem;
  color: black;
  text-decoration: none;
}

#about:hover,
#projects:hover,
#experience:hover,
#journey:hover {
  border-bottom: 1px solid black;
  cursor: pointer;
}

#aboutSelected,
#experienceSelected,
#journeySelected,
#projectsSelected {
  border-bottom: 1px solid black;
  text-decoration: none;
  font-family: "Courier New", Courier, monospace;
  font-size: 1.1rem;
  color: black;
}

.title {
  font-family: "Courier New", Courier, monospace;
  margin-left: 2vw;
  padding-top: 10vh;
  font-size: 4em;
}

.subheading {
  font-family: "Courier New", Courier, monospace;
  margin-left: 2vw;
  margin-top: -4vh;
}

#icons {
  margin-left: 2vw;
}

#socialMediaIcons {
  display: inline-block;
  height: 30px;
  padding-right: 20px;
}

#socialMediaIcons:hover {
  filter: invert(40%);
}

.projectsBox {
  /* overflow-x: auto;   */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 1vw;
  margin-right: 1vw;
}

.projectCard {
  margin: 0 10px 10px 0;
  margin-bottom: 1vh;
  position: relative;
  width: 23vw;
  background-color: #41423e;
}

#projectImage {
  /* border: 1px solid black; */
  background-color: white;
  width: 23vw;
  height: 32vh;
}

#projectCardTitle {
  font-family: "Courier New", Courier, monospace;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  color: white;
}

#projectDescription {
  /* border: 1px solid red; */
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  color: white;
  font-size: 1rem;
  height: 10vh;
  max-height: 10vh;
  overflow: auto;
}

/* #projectsLinkButton {
  fill: white;
  height: 3vh;
  margin-left: auto;
  display: block;
  position: absolute;
  bottom: 0%;
  right: 0%;
} */

#githubLinkButton {
  fill: white;
  height: 3vh;
  margin-left: auto;
  display: block;
  position: absolute;
  bottom: 0%;
  right: 0%;
}
#githubLinkButton:hover {
  filter: invert(40%);
}

/* #githubLinkButton {
  fill: white;
  height: 3vh;
  margin-left: auto;
  margin-right: 1vw;
  display: block;
  position: absolute;
  bottom: 0%;
  right: 7%;
} */

#technologyBox {
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  margin-top: 1vh;
  /* margin-bottom: 3vh;  */
  display: flex;
  flex-wrap: wrap;
  /* display:inline-block; */
  justify-content: left;
  color: white;
}

#technologyButton {
  pointer-events: none;
  border: 1px solid white;
  border-width: 2px;
  border-radius: 5px;
  margin-right: 0.3vw;
  /* width: 4vw; */
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  min-width: fit-content;
  color: white;
  text-align: center;
  /* max-width: 10vw; */
}

#htmlLink {
  color: black;
  text-decoration: none;
}

#cvBox {
  border: 1px solid black;
  font-family: "Courier New", Courier, monospace;
  border-radius: 5px;
  width: fit-content;
  color: black;
  font-size: 1.5rem;
  margin-left: 2vw;
  margin-top: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}

#yearCard {
  text-align: center;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  margin-top: 20px; /* Adjust the margin as needed */
}

#year {
  position: relative;
  width: 15ch; /* 10% of viewport width */
  height: 15ch;
  /* max-width: 500px;  */
  /* max-height: 500px; */
  background-color: #41423e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff; /* Text color */
  font-size: 1.5rem; /* Adjust the font size as needed */
  /* margin-top: 15vh; */
}

#cvBox:hover {
  background-color: rgb(202, 201, 201);
}

@media only screen and (max-width: 600px) {
  #projectCardTitle {
    /* border: 1px solid green; */
    font-family: "Courier New", Courier, monospace;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    color: white;
  }

  .projectsBox {
    /* background-color: red; */
    margin-left: 0vw;
    margin-right: 0vw;
    /* border: 1px solid red; */
    /* background-color: lightblue; */
    display: inline-table;
    width: 100vw;
  }

  .projectCard {
    margin-top: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
    border: 0cm;
    width: 98vw;
  }

  #projectImage {
    width: 98vw;
    height: 30vh;
    background-color: white;
  }

  #technologyButton {
    /* border: 1px solid red; */
    margin-right: 3vw;
    width: fit-content;
  }

  #projectDescription {
    color: white;
    font-size: 1.2rem;
    padding-left: 1vw;
    padding-right: 1vw;
    overflow: auto;
  }
}
